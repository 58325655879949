
import request from '@/utils/request';

/**
 * 获取订单配合字典数据
 * @param {  } data
 * @returns
 */
export function getOrderConfig() {
  return request({
    url: "misc-web-api/admin/dictionary/getDictionary",
    method: 'POST',
    data:{data:{ dictionaryKey:'jvm_order'}}
  })
}

// 编辑订单配置

export function editOrderConfig(data){
  return request({
    url: "misc-web-api/admin/dictionary/editDictionary",
    method: 'POST',
    data
  })
}

export default  {}
