<template>
  <section class="order-config-wrapper">
    <el-form inline :model="form" ref="form">
      <div v-for="(config, index) in form.configList" :key="config.dictionaryId">
        <common-title :title="config.title"/>
        <el-form-item
          :prop="'configList.' + index + '.dictionaryValue'"
          label-width="180"
          :label="`${config.label}：`"
          class="form-item"
          style="margin-right: 10px"
          :rules="{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }"
        >
          <el-input-number type="number" v-model="config.dictionaryValue" :min="1" />
        </el-form-item>
      </div>
      <div class="form-footer">
        <el-form-item>
          <el-button type="primary" @click="handleSubmit" :loading="confirmLoading">提交</el-button>
        </el-form-item>
      </div>
    </el-form>
  </section>
</template>

<script>
import { cloneDeep } from 'lodash';
import CommonTitle from '@/components/common-title/index.vue';
import { getOrderConfig, editOrderConfig } from '@/api/order';

export default {
  name: 'Index',
  components: {
    CommonTitle
  },
  data() {
    return {
      confirmLoading: false,
      configList: [],
      form: {
        configList: []
      }
    };
  },
  created() {
    this.getConfig();
  },
  computed: {},
  methods: {
    proving1(e) {
      const keynum = window.event ? e.keyCode : e.which; // 获取键盘码
      const keychar = String.fromCharCode(keynum); // 获取键盘码对应的字符
      if (keynum === 189 || keynum === 190 || keynum === 109) {
        this.$message.warning('禁止输入小数以及负数');
        e.target.value = ' ';
      }
    },
    async getConfig() {
      try {
        const configList = await getOrderConfig();
        configList.forEach((item) => {
          if (item.dictionaryKey === 'jvm_order_receipt') {
            item.title = '收款订单配置';
            item.label = '订单未支付自动取消时长(分钟)';
          }
          if (item.dictionaryKey === 'jvm_order_addition') {
            item.title = '加购单规则配置';
            item.label = '订单未支付自动取消时长(分钟)';
          }
          if (
            item.dictionaryKey === 'jvm_order_service' ||
            item.dictionaryKey === 'jvm_service_overTime'
          ) {
            item.title = '服务单规则配置';
          }
          if (item.dictionaryKey === 'jvm_order_service') {
            item.label = '订单完成服务后自动好评时长(天）';
            // item.label = '订单超过多久未接单提醒（小时）';
          }
          if (item.dictionaryKey === 'jvm_service_overTime') {
            item.label = '订单超过多久未接单提醒（小时）';
          }
        });
        this.form.configList = configList;
      } catch (e) {
        console.log(e);
      }
    },
    async handleSubmit() {
      if (this.confirmLoading) {
        return;
      }
      this.confirmLoading = true;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const newData = cloneDeep(this.form.configList);
            newData.forEach((item) => {
              delete item.title;
            });
            const res = await editOrderConfig({ data: newData });
            if (res.success === false) {
              this.$message.error(res.description);
              return;
            }
            this.confirmLoading = false;
            this.$message.success('操作成功');
          } catch (e) {
            this.confirmLoading = false;
          }
        } else {
          this.confirmLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.order-config-wrapper {
  background: #fff;
  margin-top: 16px;

  .form-item {
    margin-left: 20px;
    margin-top: 20px;
  }

  .form-footer {
    padding: 20px;
  }
}
</style>
